import { getBestHealth } from '@admin/api/common/common'
import { HEALTH_URL } from 'framework/enums/cacheEnum'
import { onUnmounted } from 'vue'
import { bestLineOptions } from '@admin/enums/pageEnum'
const bestHealthUrl = ref<string>('')
let intervalId: number | null = null

export default function () {
  const getBestHealthData = (healthUrl: string): Promise<string | false> => {
    return new Promise((resolve) => {
      getBestHealth({ healthUrl })
        .then((res) => {
          if (res.code === 200) {
            resolve(healthUrl)
          } else {
            resolve(false)
          }
        })
        .catch(() => {
          resolve(false)
        })
    })
  }

  const loopFindBestLine = async () => {
    const bestUrl = await Promise.race(bestLineOptions.map((item) => getBestHealthData(item.value)))

    if (bestUrl) {
      localStorage.setItem(HEALTH_URL, bestUrl as string)
      console.log('bestUrl', bestUrl)

      bestHealthUrl.value = bestUrl
    }
  }

  //开始定时
  const startPolling = (immediately = true, isLoop = false) => {
    immediately && loopFindBestLine() // 立即执行一次
    isLoop && (intervalId = window.setInterval(loopFindBestLine, 10000)) // 每30秒执行一次,默认不轮询
  }

  //停止定时器
  const stopPolling = () => {
    if (intervalId) {
      clearInterval(intervalId)
      intervalId = null
    }
  }

  //重置定时器
  const resetPolling = () => {
    console.log('重置定时器')
    stopPolling() // 停止当前的定时器
    startPolling() // 重新启动一个新的定时器
  }

  // onMounted(() => {
  //   startPolling()
  // })

  onUnmounted(() => {
    stopPolling()
  })

  return {
    resetPolling,
    bestHealthUrl,
    startPolling,
  }
}
