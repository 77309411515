<script lang="ts">
  import { defineComponent, toRefs, ref, unref } from 'vue'
  import { createBreakpointListen } from 'framework/hooks/event/useBreakpoint'
  import { prefixCls } from 'framework/settings/designSetting'
  import { useAppStore } from '@admin/store/modules/app'
  import { MenuModeEnum, MenuTypeEnum } from 'framework/enums/menuEnum'

  import { createAppProviderContext } from '@admin/components/Application/src/useAppContext'
  import useBestLine from '@admin/hooks/web/useBestLine'
  import { onMounted } from 'vue'

  const props = {
    /**
     * class style prefix
     */
    prefixCls: { type: String, default: prefixCls },
  }

  export default defineComponent({
    name: 'AppProvider',
    inheritAttrs: false,
    props,
    setup(props, { slots }) {
      const appStore = useAppStore()
      const { startPolling } = useBestLine()

      const isMobile = ref(false)
      const isIframe = ref(false)
      const isSetState = ref(false)
      // if (localStorage.getItem('userMenuGet')) {
      //   localStorage.removeItem('userMenuGet')
      // }
      // Monitor screen breakpoint information changes
      createBreakpointListen(({ screenMap, sizeEnum, width }) => {
        const lgWidth = screenMap.get(sizeEnum.SM)
        if (lgWidth) {
          isMobile.value = width.value - 1 < lgWidth
        }

        isIframe.value = appStore.isIframe // fixed: replace get iframe sessionStorage.getItem('iframe') == '1'
        handleRestoreState()
      })

      const { prefixCls } = toRefs(props)

      // Inject variables into the global
      createAppProviderContext({ prefixCls, isMobile, isIframe })

      /**
       * Used to maintain the state before the window changes
       */
      function handleRestoreState() {
        if (unref(isMobile)) {
          if (!unref(isSetState)) {
            isSetState.value = true
            const {
              menuSetting: { type: menuType, mode: menuMode, split: menuSplit },
            } = appStore.getProjectConfig

            appStore.setProjectConfig({
              menuSetting: {
                type: MenuTypeEnum.SIDEBAR,
                mode: MenuModeEnum.INLINE,
                split: false,
                collapsed: true,
              },
            })
            appStore.setBeforeMiniInfo({ menuMode, menuCollapsed: false, menuType, menuSplit })
          }
        } else {
          if (unref(isSetState)) {
            isSetState.value = false
            const { menuMode, menuCollapsed, menuType, menuSplit } = appStore.getBeforeMiniInfo
            appStore.setProjectConfig({
              menuSetting: {
                type: menuType,
                mode: menuMode,
                collapsed: menuCollapsed,
                split: menuSplit,
              },
            })
          }
        }
      }

      onMounted(() => {
        startPolling()
      })
      return () => slots.default?.()
    },
  })
</script>
